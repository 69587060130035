$ = require('jquery')
$ ->
  $(".js-email-ok").on "click", (e) ->
    $container = $(e.target).closest(".js-email-template")
    $container.find(".js-email-preview").trigger("click")
    e.preventDefault()

  $(".js-email-edit").on "click", (e) ->
    $(".js-dropdown").hide()
    $container = $(e.target).closest(".js-email-template")
    $container.find(".js-email-content").closest(".js-dropdown").show()
    e.preventDefault()


  $(".js-email-preview").on "click", (e) ->
    $(".js-dropdown").hide()
    $container = $(e.target).closest(".js-email-template")
    content = $container.find(".js-email-content")
    data =
      'body': content.val()
      'email_type': $container.attr("class").split(" ").pop().replace("js-", "")

    $.ajax
      type: "POST",
      headers: { 'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content },
      url: "/email_preview",
      data: data,
      dataType: 'script'

    $container.find(".js-preview-content").closest(".js-dropdown").show()
    e.preventDefault()

  $(".js-email-test").on "click", (e) ->
    $(".js-dropdown").hide()
    $container = $(e.target).closest(".js-email-template")
    content = $container.find(".js-email-content")
    data =
      'body': content.val()
      'email_type': $container.attr("class").split(" ").pop().replace("js-", "")

    $.ajax
      type: "POST",
      url: "/email_test",
      headers: { 'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content },
      data: data,
      dataType: 'text/html'
    alert("Please check your email.")
    e.preventDefault()
