var $ = require('jquery');
require('select2');

var readyReports = function () {
  if ($('#report_form_client_id').length == 0) {
    return false;
  }
  $('#report_form_client_id').select2({});
  $('#report_form_assessment_group_id').select2({
    ajax: {
      delay: 250,
      url: '/engaged_and_completed_runs',
      headers: { 'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content },
      data: function (params) {
        var ids = $('#report_form_client_id').select2('data').map(function (opt) { return opt.id; });
        var query = { client_ids: ids.join(',') };
        return query;
      }
    },
    placeholder: 'Select a run',
  });

  $('form.report_form').on('ajax:beforeSend', function (e, xhr, settings) {
    var ids = $('#report_form_assessment_group_id').select2('data').map(function (opt) { return opt.id; });
    if (ids.length == 0) {
      return false;
    }
  });

  function setReportLinks() {
    var $clientSelector = $('#report_form_client_id');
    var $runSelector = $('#report_form_assessment_group_id')
    if (($clientSelector.length > 0) && ($runSelector.length > 0)) {
      var client = $clientSelector.select2('data').shift();
      var run = $runSelector.select2('data').shift();
      if (client != undefined) {
        $('.js-export-all-runs').attr('href', "assessment_groups.xlsx?client_id=" + client.id);
        $('.js-export-all-runs').show();
      } else {
        $('.js-export-all-runs').hide();
      }

      if ((run != undefined) && (client != undefined)) {
        $('.js-export-alt-runs').attr('href', "assessment_groups.xlsx?alt_report=true&client_id=" + client.id + "&assessment_group_id=" + run.id);
        $('.js-export-alt-runs').show();
      } else {
        $('.js-export-alt-runs').hide();
      }
    }
  }

  setReportLinks();
  $('#report_form_client_id, #report_form_assessment_group_id').on('change.select2', function (e) {
    setReportLinks()
    // $('form.report_form').submit();
  });
};

$(document).ready(readyReports);
$(document).on('turbolinks:load', readyReports);
