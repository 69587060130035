angular.module('client.filters').filter 'orderByIfPersisted', ()->
  return (items, name)->
    return items unless items
    flag = true
    for item in items
      unless item.id
        flag = false
        break
    if flag
      return items.sort (x,y)->
        x[name] > y[name]
    else
      return items
