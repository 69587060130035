$ = require('jquery')
import "jquery"
import "angular"
require('angular-resource')
require('angularjs-rails-resource')
require('angular-ui-sortable')

window.clientsApp = angular.module('eyClientsApp', ['eyClientsApp.controllers', 'ngResource', 'ui.sortable' , 'client.services', 'reusableDirectives' , 'reusableFactories' , 'ey' , 'client.filters'])

clientsApp.config([ "$httpProvider", ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').content
])
window.ClientsControllerModule = angular.module('eyClientsApp.controllers', ['ngResource'])

angular.module('client.services', ['rails'])
angular.module('client.filters' , [])
