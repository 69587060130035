$ = require('jquery')
ready = ->
  $('#client_selector_').change ->
    client_id = $(this).val()
    if client_id
      Turbolinks.visit("/dashboard/administration?client_id=#{client_id}", { action: "replace" })
    else
      Turbolinks.visit("/dashboard/administration", { action: "replace" })

  # ============================================================
  # TODO: remove this! It breaks critial authorization logic!
  $('#switch_user').change ->
    Turbolinks.visit("/users/#{$(this).val()}/log_in_as", { action: "replace" })
  # ============================================================

$(document).on('ready turbolinks:load', ready)
