$ = require('jquery')
$ ->
  active_tab = (active_tabs) ->
    tabs = ['administration_tab', 'reports_tab', 'assessment_tab' , 'frameworks' , 'clients']
    for t in tabs

      in_active = false

      for tab in active_tabs
        if tab is t
          in_active = true
          break
      $("#" + t).removeClass('active_tab') unless in_active
    for t in active_tabs
      $("#" + t).addClass('active_tab')

  href = window.location.href

  if href.indexOf('administration') > 0
    active_tab(['administration_tab' , 'frameworks'])
  else if href.indexOf('clients') > 0
    active_tab(['administration_tab' , 'clients'])
  else if href.indexOf('master_frameworks') > 0
    active_tab(['administration_tab' , 'frameworks'])
  else if href.indexOf('client_frameworks') > 0
    active_tab(['administration_tab' , 'frameworks'])
  else if href.indexOf('ey_users') > 0
    active_tab(['administration_tab' , 'ey_users'])

  else if href.indexOf('reports') > 0
    active_tab(['reports_tab'])
  else if href.indexOf('assessment') > 0
    active_tab(['assessment_tab'])

  else if href.indexOf('/contact') > 0
    # do nothing

  else if href.indexOf('/users/') > 0
    # do nothing

  else
    active_tab(['home_tab'])



