angular.module('assessments.services')
  .factory('AssessmentGroup', ['railsResourceFactory', (railsResourceFactory)->
    railsResourceFactory
      url: '/assessment_groups'
      name: 'assessment_group'
      serializer: 'AssessmentGroupSerializer'
  ])
  .factory('AssessmentGroupSerializer', [ 'railsSerializer', (railsSerializer)->
    return railsSerializer ()->
      @only('clientFrameworkIds' , 'masterFrameworkId' , 'name' , 'clientId', 'notificationTemplateBody', 'invitationTemplateBody', 'landingPageText' )
  ])

