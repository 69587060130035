import Highcharts from 'highcharts'

window.installPieChartAnswerStatistics = function () {
  var isZero = 0;

  isZero += window.graph_data_raw['answers_excellent'];
  isZero += window.graph_data_raw['answers_good'];
  isZero += window.graph_data_raw['answers_bad'];
  isZero += window.graph_data_raw['answers_na'];


  if (isZero > 0) {
    var data_answers = [{
      color: '#5E8F2B', //green
      name: window.graph_data_raw['rating_labels'][2],
      y: window.graph_data_raw['answers_excellent'],
    },

    {
      color: '#F7B500', //yellow
      name: window.graph_data_raw['rating_labels'][1],
      y: window.graph_data_raw['answers_good'],
    },

    {
      color: '#E02020', //red
      name: window.graph_data_raw['rating_labels'][0],
      y: window.graph_data_raw['answers_bad'],
    },

    {
      color: '#D9D8D8', //light grey
      name: window.graph_data_raw['rating_labels'][3],
      y: window.graph_data_raw['answers_na'],
    }
    ];
  } else {
    var data_answers = [{
      color: '#D9D8D8', //light grey
      name: 'No assessments answer data',
      y: 100
    }];
  }
  // Create the chart
  Highcharts.chart('chart-pie-answers-' + window.graph_id, {
    chart: {
      type: 'pie',
      height: 420
    },
    credits: false, //licensing for highcharts
    legend: {
      enabled: true,
      borderColor: null,
      align: 'center',
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      x: 0,
      y: 0,
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '10px',
        font: 'Open Sans, sans-serif',
        color: 'rgba(0,0,0,0.5)'
      },
      itemMarginTop: 20,
      symbolWidth: 20,
      symbolHeight: 20
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        center: ['50%', '50%'],
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      },
    },
    tooltip: {
      valueSuffix: ''
    },
    series: [{
      name: 'Answers(s)',
      data: data_answers,
      size: '100%',
      innerSize: '80%',
      dataLabels: {
        formatter: function () {
          return '<b>' + this.y + '%</b>';
        }
      }
    }]
  });
}
