$ = require('jquery')
window.Assessment =
  updatePercentComplete: () ->
    Assessment.markControlsComplete()
    num_controls = $('.control').length
    answered_controls = $('.control.complete').length

    if ((num_controls > 0) && (answered_controls > 0))
      percent_complete = answered_controls/num_controls*100;
    else
      percent_complete = 0

    percent_complete = Math.round(percent_complete)
    $('.assessment-percent-complete').html((percent_complete).toString() + '%')

  collapseProcess: (elem) ->
    $(elem).toggleClass('is-open')
    $(elem).parent().parent().find('.control-objective').toggle()

  collapseControlObjective: (elem) ->
    $(elem).toggleClass('is-open')
    $(elem).parent().parent().toggleClass('is-closed');
    $(elem).parent().parent().parent().find('ul').toggle();

  collapseProcess: (elem) ->
    $(elem).toggleClass('is-open')
    $(elem).parent().parent().find('.control-objective').toggle()

  collapseControlObjective: (elem) ->
    $(elem).toggleClass('is-open')
    $(elem).parent().parent().toggleClass('is-closed');
    $(elem).parent().parent().parent().find('ul').toggle();

  showComment: (elem) ->
    $(elem).parent().parent().parent().find('.control-comment').toggle()

  markControlsComplete: ->
    $('li.control').each ->
      complete = ($(this).find('input[type=radio]:checked').length > 0) ? true : false
      textarea = $(this).find('.control-comment textarea').val();
      fileUpload = $(this).find('.js-file-upload');
      mandatoryCommenting = $(this).data('mandatory-commenting');
      commentingLogic = $(this).data('commenting-logic');
      answer = Number.parseInt($(this).find('input[type=radio]:checked').val())

      if fileUpload.attr('data-has-file-upload') == "true"
        if ((fileUpload.attr('data-file-is-attached') == "false") && (fileUpload.attr('data-required-file-upload') == "true"))
          complete = false

      if (textarea.length > 0)
      else
        complete = false if (mandatoryCommenting == true) && !commentingLogic
        complete = false if (mandatoryCommenting == true) && commentingLogic && commentingLogic != answer
        complete = false if (mandatoryCommenting == false) && commentingLogic && commentingLogic != answer

      if complete
        $(this).addClass('complete');
        $(this).find('.control-status-label span').removeClass('incomplete').addClass('complete').text('Complete');
        $(this).find('.control-comment .mandatory-text').hide();
      else
        $(this).removeClass('complete');
        $(this).find('.control-status-label span').removeClass('complete').addClass('incomplete').text('Incomplete');
        $(this).find('.control-comment .mandatory-text').show();

$(document).on 'turbolinks:load', ->
  $('body').on 'change', '.control input[type=radio]', ->
    Assessment.updatePercentComplete()

  $('body').on 'keyup', '.mandatory-comment textarea', ->
    Assessment.updatePercentComplete()

  $('body').on 'click', '.icon-comment', (e) ->
    Assessment.showComment(e.currentTarget)
