angular.module('client.services')
  .factory('ClientSerializer', ['railsSerializer', (railsSerializer) ->
    return railsSerializer ()->
      @only('name' , 'divisionLabel' , 'subdivisionLabel' , 'rating1Label' , 'rating2Label' , 'rating3Label' , 'rating4Label')
  ])
  .factory('Client', ['railsResourceFactory', (railsResourceFactory) ->
    railsResourceFactory
      url: '/clients'
      name: 'client'
      serializer: 'ClientSerializer'
      errors: {}
  ])

