window.Framework =
  enableInputs: () ->
    $('fieldset').removeAttr('disabled');

  enableTextareas: () ->
    $('textarea').removeAttr('disabled');

  disableInputs: () ->
    $('fieldset').attr('disabled', 'disabled');

  disableTextareas: () ->
    $('textarea').attr('disabled', 'disabled');

  enableSorting: () ->
    $('.processes').sortable({disabled: false});
    $('.control-objectives').sortable({disabled: false});
    $('.controls').sortable({disabled: false});

  disableSorting: () ->
    $('.processes').sortable({disabled: true});
    $('.control-objectives').sortable({disabled: true});
    $('.controls').sortable({disabled: true});

  enableAddDelete: () ->
    $('.icon-delete').show();
    $('.icon-add').show();

  disableAddDelete: () ->
    $('.icon-delete').hide();
    $('.icon-add').hide();

  enableEntirePage: () ->
    Framework.enableInputs();
    Framework.enableSorting();
    Framework.enableAddDelete();
    Framework.enableTextareas();

  disableEntirePage: () ->
    Framework.disableInputs();
    Framework.disableSorting();
    Framework.disableAddDelete();
    Framework.disableTextareas();

  clientFrameworkName: () ->
    name = '';
    client = $('#client_select option:selected').text();
    division = $('#division_select option:selected').text();
    # subdivision = $('#subdivision_select option:selected').text();
    master_framework = $('#master_framework_select option:selected').text();
    name += (client + ' - ') if (client > '')
    name += (division + ' - ') if (division > '')
    # name += (subdivision + ' - ') if (subdivision > '')
    name += master_framework if (master_framework > '')
    return name
