angular.module('assessments.services')
  .factory('Division', ['railsResourceFactory', (railsResourceFactory)->
    railsResourceFactory
      url: '/divisions'
      name: 'division'
      serializer: 'DivisionSerializer'
      errors: {}
    ])
  .factory('DivisionSerializer', [ 'railsSerializer', (railsSerializer)->
    return railsSerializer ()->
      @only('name' , 'client_id')
  ])
