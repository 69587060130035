
$ = require('jquery')
require('angular-resource')
require('angularjs-rails-resource')

window.assessmentApp = angular.module('eyAssessmentsApp', ['eyAssessmentsApp.controllers', 'ngResource' , 'assessments.services'  , 'assessments.filters', 'reusableDirectives' , 'reusableFactories' , 'ey'])

window.AssessmentsControllerModule = angular.module('eyAssessmentsApp.controllers', ['ngResource' ])

assessmentApp.config([ "$httpProvider", function($httpProvider) {
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').content
}]);

angular.module('assessments.filters'  , []);
angular.module('assessments.services', ['rails']);
