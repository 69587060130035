FrameworksApp.Controllers.controller('MasterFrameworkController', ['$scope', '$resource', function($scope, $resource) {
  var MasterFramework = $resource('/master_frameworks/:id.json', {id: '@id'}, {
    find: {method: 'GET'},
    create: {method: 'POST'},
    update: {method: 'PUT'}
  });

  if (page_data.id > 0) {
    $scope.my_data = MasterFramework.find({id: page_data.id},
      function (object) {},
      function (object) { alert('ERROR: Unable to load Framework'); }
    );
  } else {
    $scope.my_data = newFramework();
  }

  $scope.sortProcesses = {
    stop: function(e, ui) {
      repositionChildren($scope.my_data.processes_attributes);
    }
  };

  $scope.sortControlObjectives = {
    stop: function(e, ui) {
      // get the parent "process" object
      parent = ui.item.scope().$parent.process
      repositionChildren(parent.control_objectives_attributes);
    }
  };

  $scope.sortControls = {
    stop: function(e, ui) {
      // get the parent "control_objective" object
      parent = ui.item.scope().$parent.control_objective
      repositionChildren(parent.controls_attributes);
    }
  };


  // METHODS USED INTERNALLY
  function newProcess() {
    return {position: 1, control_objectives_attributes: [newControlObjective()]};
  }
  function newControlObjective() {
    return {position: 1, controls_attributes: [newControl()]};
  }
  function newControl() {
    return {position: 1};
  }
  function newFramework() {
    return {processes_attributes: [newProcess()]};
  }
  function repositionChildren(the_collection) {
    for (var i=0; i<the_collection.length; i++) {
      the_collection[i].position = i+1;
    }
  }
  function getNewRecord(record_type) {
    var new_record = false;
    switch(record_type) {
      case 'process':
        new_record = newProcess(); break;
      case 'control_objective':
        new_record = newControlObjective(); break;
      case 'control':
        new_record = newControl(); break;
      default:
        console.log('invalid record_type!');
    }
    return new_record;
  }
  function getAttributesName(child_type) {
    var attributes_name = '';
    switch(child_type) {
      case 'process':
        attributes_name = 'processes_attributes'; break;
      case 'control_objective':
        attributes_name = 'control_objectives_attributes'; break;
      case 'control':
        attributes_name = 'controls_attributes'; break;
      default:
        console.log('invalid child_type!');
    }
    return attributes_name;
  }

  // METHODS FOR THE FRONTEND
  $scope.collapse = function(obj) {
    obj.collapse = !obj.collapse;
  }

  $scope.deleteThis = function(the_collection, index, child_type) {
    attributes_name = getAttributesName(child_type);
    record_name = $scope.localize(child_type)

    // we need to skip over "empty" records with "_destroy"
    actual_records = 0;
    for (var i in the_collection[attributes_name]) {
      if (typeof the_collection[attributes_name][i]._destroy === 'undefined'){
        actual_records += 1;
      }
    }

    if (actual_records > 1) {
      if (confirm('Are you sure you want to delete this ' + record_name)) {
        id = the_collection[attributes_name][index].id;
        // remove it from the array
        the_collection[attributes_name].splice(index, 1);
        // add the blank object, with a "_destroy" (and ID) for Rails
        if (id) {
         the_collection[attributes_name].push({"_destroy":true, "id": id});
        }
        repositionChildren(the_collection);
      }
    } else {
      alert('You must have at least 1 ' + record_name);
    }
  }

  $scope.addChildButton = function(the_collection, index, child_type) {
    var attributes_name = getAttributesName(child_type);
    var new_record = getNewRecord(child_type);

    the_collection = the_collection[attributes_name]; // eg: mydata['processes_attributes']
    the_collection.splice(index+1, 0, new_record); // insert the new record after the index
    repositionChildren(the_collection); // and set new "position" values for each record
  }

  $scope.doSave = function() {
    Framework.disableEntirePage();
    if (!$scope.submitting)
    {
      $scope.submitting = true;
      if ($scope.my_data.id) {
        $scope.doUpdate();
      } else {
        $scope.doCreate();
      }
    }
  }

  $scope.doCreate = function() {
    MasterFramework.create({master_framework: $scope.my_data, authenticity_token: page_data.authenticity_token},
      function (object) {
        $scope.my_data = object;
        $scope.submitting = false;
        alert('Framework created successfully');
        Framework.enableEntirePage();
      },
      function (object) {
        $scope.my_data = object.data;
        $scope.submitting = false;
        alert('Error creating Framework!');
        Framework.enableEntirePage();
      }
    );
  }

  $scope.doUpdate = function() {
    MasterFramework.update({id: $scope.my_data.id, master_framework: $scope.my_data, authenticity_token: page_data.authenticity_token},
      function (object) {
        $scope.my_data = object;
        $scope.submitting = false;
        alert('Framework updated successfully');
        Framework.enableEntirePage();
      },
      function (object) {
        $scope.my_data = object.data;
        $scope.submitting = false;
        alert('Error updating Framework!');
        Framework.enableEntirePage();
        $scope.expandRecordsWithErrors();
      }
    );
  }

  $scope.expandRecordsWithErrors = function () {
    for (var i in $scope.my_data.processes_attributes) {
      var process = $scope.my_data.processes_attributes[i];
      for (var j in process.control_objectives_attributes) {
        var control_objective = process.control_objectives_attributes[j];
        if ($scope.hasErrors(control_objective)) {
          process.collapse = false;
        }
        for (var k in control_objective.controls_attributes) {
          var control = control_objective.controls_attributes[k];
          if ($scope.hasErrors(control)) {
            control_objective.collapse = false;
            process.collapse = false;
          }
        }
      }
    }
  }

  $scope.hasErrors = function(obj) {
    if (obj.errors) {
        var error_count = Object.keys(obj.errors).length;
        if (error_count > 0) {
          return true;
        }
    }
    return false;
  }

  $scope.localize = function(string) {
    var ret = '';
    switch(string) {
      case 'process':
        ret = 'Subprocess'; break;
      case 'control_objective':
        ret = 'Control Objective'; break;
      case 'control':
        ret = 'Control'; break;
    }
    return ret;
  }
}])
