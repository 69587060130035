angular.module('client.services')
  .factory('User', ['railsResourceFactory', (railsResourceFactory)->
    railsResourceFactory
      url: '/users'
      name: 'user'
      serializer: 'UserSerializer'
      errors: {}
  ])

  .factory('UserSerializer', [ 'railsSerializer', (railsSerializer)->
    return railsSerializer ()->
      @only('firstname' , 'lastname' , 'email' , 'roles', 'client_id', 'password', 'password_confirmation')
  ])

