$ = require('jquery')
import "jquery"
import "angular"
require('angular-resource')
require('angularjs-rails-resource')
require('angular-ui-sortable')

window.FrameworksApp =
  Application: angular.module 'eyApp', ['eyApp.controllers', 'ngResource' , 'ui.sortable' ]
  Controllers: angular.module 'eyApp.controllers', ['ngResource']
