angular.module('assessments.services')
  .factory('SubDivision', ['railsResourceFactory', (railsResourceFactory)->
    railsResourceFactory
      url: '/subdivisions'
      name: 'subdivision'
      serializer: 'SubDivisionSerializer'
      errors: {}
  ])
  .factory('SubDivisionSerializer', ['railsSerializer', (railsSerializer)->
    return railsSerializer ()->
      @only('name' , 'division_id')
  ])