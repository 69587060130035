// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
import {$, jQuery} from 'jquery'
import("jquery-ui");
import("jquery-ui/ui/widgets/sortable");
import("jquery-ui/ui/widgets/tooltip");
require("@rails/ujs").start();
require("select2");
import 'angular'

import '../application/stylesheets/application.sass';

import 'coffee-loader!../application/javascripts/angular/general.coffee';
import '../application/javascripts/angular/angular_assessments.js';
import '../application/javascripts/angular/angular_clients.js';
import '../application/javascripts/angular/angular_frameworks.js';

import '../application/javascripts/application.js';
import 'coffee-loader!../application/javascripts/assessments.coffee';
import '../application/javascripts/bar_chart.js';
import '../application/javascripts/clients.js';
import '../application/javascripts/dashboard_filter';
import 'coffee-loader!../application/javascripts/dashboard.coffee';
import 'coffee-loader!../application/javascripts/email_templates.coffee';
import 'coffee-loader!../application/javascripts/ey_users.coffee';
import 'coffee-loader!../application/javascripts/frameworks.coffee';
import '../application/javascripts/landing_page.js';
import '../application/javascripts/pie_chart_answer_statistics';
import '../application/javascripts/pie_chart.js';
import '../application/javascripts/reports_filter.js';
import '../application/javascripts/require_assessmentsapp.js';
import '../application/javascripts/require_frameworks.js';
import 'coffee-loader!../application/javascripts/tabs.coffee';
import 'coffee-loader!../application/javascripts/tooltips.coffee';

// require("../../assets/javascripts/application").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const fonts = require.context('../application/fonts', true)

const images = require.context('../application/images', true)
const imagePath = (name) => images(name, true)
