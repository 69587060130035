var $ = require('jquery')
var readyDashboard = function() {
  $('#dashboard_form_client_ids').select2({
    closeOnSelect: false,
  });
  $('#dashboard_form_assessment_group_ids').select2({
    ajax: {
      delay: 250,
      url: '/active_runs_for_clients',
      headers: { 'X-CSRF-Token': $('[name="csrf-token"]')[0].content },
      data: function (params) {
        var ids = $('#dashboard_form_client_ids').select2('data').map(function(opt) { return opt.id; } );
        var query = { client_ids: ids.join(',') };
        return query;
      }
    },
    placeholder: 'Please select a client',
    closeOnSelect: false,
    allowClear: true,
  });

}
$(document).ready(readyDashboard);
$(document).on('turbolinks:load', readyDashboard);