angular.module('ng')
  .directive('ngFocus', [ '$timeout', ($timeout)->
    link: ( scope, element, attrs )->
      scope.$watch attrs.ngFocus , (val)->
        if angular.isDefined( val ) && val
          $timeout ()->
            element[0].focus()
      , true
  ])
  .directive('elemReady', ['$parse', ($parse) ->
    restrict: 'A'
    link: ($scope, elem, attrs) ->
      elem.ready ->
        $scope.$evalAsync ->
          func = $parse(attrs.elemReady)
          func $scope
  ])

angular.module('ey')
  .directive('attributeUpdated', [ ()->
    return (scope, element, attrs)->
      scope.$watch attrs.attributeUpdated , (val)->
        if angular.isDefined( val ) && val
          element.addClass('attribute-updated')
        else
          element.removeClass('attribute-updated')
      ,
        true
  ])
  .directive('attributeError', [ ()->
    return (scope, element, attrs)->
      scope.$watch attrs.attributeError , (val)->
        if angular.isDefined( val ) && val
          console.log element
          element.addClass('attribute-error')
        else
          element.removeClass('attribute-error')
      ,
        true
  ])
